(function(window) {
  window.__env = window.__env || {};
  // baseUrl = 'http://localhost/'; // svil
  baseUrl = '/'; // prod

  // API url
  // window.__env.apiUrl = baseUrl+'api-sendtrack';
  window.__env.apiUrl = baseUrl+'api-sendtrack/v2';


}(this));
